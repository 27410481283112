<template>
  <div v-if="$vuetify.breakpoint.smAndDown">
    <v-list>
      <div
        v-for="item in routers"
        :key="item.name"
      >
        <v-list-item
          v-if="item.childs.length === 0"
          :value="true"
          link
          :to="item.childs.length > 0 ? null : item.router"
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
        <v-list-group
          v-if="item.childs.length > 0"
          :value="true"
          no-action
          :prepend-icon="item.icon"
        >
          <template v-slot:activator>
            <v-list-item-title>{{item.name}}</v-list-item-title>
          </template>
          <v-list-item
            v-for="(child, i) in item.childs"
            :key="i"
            link
            :to="item.router + child.router"
          >
            <!-- <v-list-item-icon>
              <v-icon v-text="child.icon"></v-icon>
            </v-list-item-icon> -->
            <v-list-item-title v-text="child.name"></v-list-item-title>

          </v-list-item>
        </v-list-group>
      </div>
    </v-list>
  </div>
  <div v-else>
    <v-menu
      v-for="(item, index) in routers"
      :key="index"
      rounded
      offset-y
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="white--text"
          text
          v-bind="attrs"
          v-on="on"
          :to="item.childs.length > 0 ? null : item.router"
          v-model="isRouterActive"
        >
          <v-icon
            left
          >
            {{ item.icon }}
          </v-icon>
          {{ item.name }}
          <v-icon
            v-if="item.childs.length > 0"
            right
          >
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>
      <v-list
        v-if="item.childs.length > 0"
        dense
      >
        <v-list-item
          v-for="(child, childIndex) in item.childs"
          :key="childIndex"
          :to="item.router + child.router"
          link
        >
          <v-list-item-title>{{ child.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu
      v-if="fiwareServiceList.length > 0"
      rounded
      offset-y
      v-model="isServiceActive"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="white--text"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            left
          >
            mdi-cogs
          </v-icon>
          {{ fiwareService }}
          <v-icon
            right
          >
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          link
          v-for="(service, serviceIndex) in fiwareServiceList"
          :key="serviceIndex"
          :disabled="fiwareService === service"
          @change="confirmDialog = { isActive: true, name: service }"
        >
          <!-- @change="(event) => { $store.dispatch('setFiwareService', event) }" -->
          <v-list-item-title>{{ service }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu
      rounded
      offset-y
      v-model="isProfileActive"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="white--text"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            left
          >
            mdi-account
          </v-icon>
          {{ userProfile.username }}
          <v-icon
            right
          >
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>

      <v-card
        width="40vh"
        tile
      >
        <v-list dense>
          <v-list-item dense>
            <v-list-item-avatar>
              <!-- <img
                src="https://cdn.vuetifyjs.com/images/john.jpg"
                alt="John"
              > -->
              <v-avatar
                color="primary white--text"
                size="56"
              >{{ profileAvatar }}</v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-capitalize">{{ userProfile.firstName }} {{ userProfile.lastName }}</v-list-item-title>
              <v-list-item-subtitle>{{ userProfile.email }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <div>
                <v-btn
                  x-small
                  icon
                  href="https://id.meca.in.th/auth/realms/dara/account/"
                  target="_blank"
                >
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
                <v-btn
                  x-small
                  icon
                  @click="$emit('logout')"
                >
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <confirm-dialog v-model="confirmDialog.isActive" type="change service"
      :title="confirmDialog.name"
      @confirm="confirmChangeDialog()" />
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'RouterLink',
  components: {
    ConfirmDialog: () => import('@/components/ConfirmDialog.vue')
  },
  data: () => ({
    isProfileActive: false,
    isServiceActive: false,
    routers: [
      { name: 'Home', router: '/', icon: 'mdi-home', childs: [] },
      { name: 'End-Pont', router: '/end-point', icon: 'mdi-link', childs: [] },
      { name: 'MQTT', router: '/mqtt', icon: 'mdi-access-point', exact: true, childs: [
        { name: 'Connections', router: '/connections', icon: 'mdi-cogs' },
        { name: 'Subscriptions', router: '/subscriptions', icon: 'mdi-cogs' }
      ] },
      { name: 'Fiware', router: '/fiware', icon: 'mdi-radio-tower', exact: true, childs: [
        { name: 'Services', router: '/services', icon: 'mdi-cogs' },
        { name: 'Entities', router: '/entities', icon: 'mdi-account-multiple-outline' },
        { name: 'Subscriptions', router: '/subscriptions', icon: 'mdi-account-multiple-outline' },
        { name: 'Registration', router: '/registration', icon: 'mdi-account-multiple-outline' }
      ] }
    ],
    confirmDialog: {
      isActive: false,
      name: null
    }
  }),

  methods: {
    // ...mapActions(['setFiwareService']),
    tt: function (on) {
      console.log(on)
    },
    confirmChangeDialog: function () {
      this.$store.commit('setFiwareService', this.confirmDialog.name)
      localStorage.setItem('myCat', 'Tom')
      // this.setFiwareService = this.confirmDialog.name
    }
  },

  computed: {
    ...mapGetters(['userProfile', 'fiwareService', 'fiwareServiceList']),
    // isRouterActive: function () {
    //   return this.$route.fullPath.match(/\//g).length === 2
    // },
    isRouterActive: function () {
      let path = this.$route.fullPath.split('/').filter(r => r)[0]
      let routerActive = false
      this.routers.filter(router => router.childs.length > 0).forEach(rt => {
        if (rt.name.toLowerCase() === path) {
          routerActive = true
        }
      })
      return routerActive
    },
    // userProfile: function () {
    //   const init = this.username.trim().toUpperCase().charAt(0)
    //   return { initials: init, fullName: this.username, email: this.email }
    // },
    profileAvatar: function () {
      let avatar = 'N/A'
      if (this.userProfile.firstName) {
        avatar = this.userProfile.firstName.charAt(0) + this.userProfile.lastName.charAt(0)
      }
      return avatar.toUpperCase()
    }
  }
}
</script>
